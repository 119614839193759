.book[disabled] {
  background-color: $color-grey-disabled;
  color: $color-grey-disabled-text;
  cursor: default;
}

.book {
  width: 20rem;
  height: 2.5rem;
  border-radius: 0.4rem;
  background-color: $color-light-green;
  border-style: none;
  color: $background-color-white;
  font-weight: 700;
  font: normal normal bold 1rem/1.375rem Open Sans;
  cursor: pointer;
}

.book[disabled]:hover {
  background-color: $color-grey-disabled;
  color: $color-grey-disabled-text;
}

.book:hover {
  background-color: rgba(139, 195, 74, 0.842);
}
