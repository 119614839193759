@import "../../assets/variables";

.boundary {
  padding: 20px;
  background: radial-gradient(circle, rgba(139, 195, 74, 0.5) 0%, rgba(139, 195, 74, 0) 40%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;

  & div {
    width: 0px;
    height: 0px;
    background-color: green;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }
}

.lineTo:after {
  display: block;
  content: "";
  height: $gridSize;
  position: relative;
  top: calc(($gridSize + 2px) * -1);
  z-index: 1;
}

.lineTo {
  border-radius: 0px;
}
