.calendar-cont {
  position: absolute;
  width: 14rem;
}

.calendar-and-button {
  display: flex;
  align-items: center;
}

#today-button {
  padding-top: 0;
  padding-left: 0.7rem;
  color: $color-light-gray;
  &:hover {
    color: $color-light-green;
  }
  display: none;
}

.custom-calendar {
  background-color: $color-light-green;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0.1rem 0.2rem 0.5rem;
  padding: 0 0.5rem 0 0.3rem;
  color: $background-color-white;
  height: 2.5rem;
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: center;

  .MuiSvgIcon-root {
    font-size: 0.75rem;
  }

  .label-calendar {
    font-size: 1rem;
    padding: 0;
  }
}

.react-calendar {
  max-width: 100%;
  background: $background-color-white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125rem;
  height: 18rem;
  border: 0.0625rem solid $border-color-grey;
  border-radius: 1rem;
}

.custom-calendar .MuiSvgIcon-root:hover {
  color: $background-date-color;
}

#calendar-label {
  cursor: pointer;
}
