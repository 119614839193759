.sketch-container {
  .close-menu {
    position: absolute;
    right: 0.125rem;
    top: -0.25rem;
    font: $font-style-small-bold;
    color: $color-red;
    cursor: pointer;
    user-select: none;
  }

  .sketch-picker {
    padding-top: 0.75rem !important;
  }
}
