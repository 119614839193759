#select {
  border-radius: 0.4rem;
  box-shadow: 0rem 0.3rem 0.7rem rgba(0, 0, 0, 0.161);
  margin-top: 0.7rem;
  height: 100%;
  width: 100%;
}
.others-container {
  width: 20rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.checkbox-label {
  margin-top: 0.7rem;
  position: relative;
  padding-left: 2rem;
}

.checkbox-label input {
  cursor: pointer;
  visibility: hidden;
}

.checkmark {
  border: 0.05rem solid $color-very-light-gray;
  box-shadow: 0rem 0.3rem 0.7rem rgba(0, 0, 0, 0.161);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 0.4rem;
}

.checkbox-label:hover input ~ .checkmark {
  border: 0.05rem solid $color-light-green;
}

.checkbox-label input:checked ~ .checkmark {
  background-color: $color-light-green;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-label .checkmark:after {
  left: 0.45rem;
  top: 0.13rem;
  width: 0.3rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  transform: rotate(45deg);
}

.checkbox-label input:disabled ~ .checkmark {
  border: none;
  cursor: default;
}
