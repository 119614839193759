.settings-cancel {
  width: 9rem;
  height: 2.5rem;
  border-radius: 0.4rem;
  background-color: $color-light-red;
  border-style: none;
  color: $background-color-white;
  font-weight: 700;
  font: normal normal bold 1rem/1.375rem Open Sans;
  cursor: pointer;
  margin-right: 1rem;
  box-shadow: $box-shadow-dark;
}

.settings-save {
  width: 9rem;
  height: 2.5rem;
  border-radius: 0.4rem;
  background-color: $color-light-green;
  border-style: none;
  color: $background-color-white;
  font-weight: 700;
  font: normal normal bold 1rem/1.375rem Open Sans;
  cursor: pointer;
  margin-left: 1rem;
  box-shadow: $box-shadow-dark;
}

.settings-save:hover {
  background-color: $color-dark-green;
}

.settings-cancel:hover {
  background-color: $color-red;
}

.MuiSvgIcon-root.start-adornment-icon:hover {
  color: rgba(0, 0, 0, 0.54);
}

.draggable-item {
  padding: 0.5rem;
  max-width: 20rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  background-color: $background-color-white;
  box-shadow: $box-shadow-dark;
}

.draggable-container {
  display: flex;
  flex-direction: row;
}

.draggable-item-building {
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.25rem;
  min-width: 8rem;
  height: 20rem;
  overflow-y: auto;
  margin-right: 0.5rem;
  box-shadow: $box-shadow-dark;
  background-color: $background-draggable;
}

.draggable-item:hover {
  background-color: $background-draggable;
}

.draggable-item-building:hover {
  background-color: rgba(139, 195, 74, 0.2);
}

.settings-upper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 2rem;
}

.settings-order-container {
  width: 70%;
  margin: 1rem;
  overflow: auto;
}
.settings-form {
  margin: 1rem;
}

.settings-wrapper {
  margin-top: 5rem;
}

.settings-buttons {
  display: flex;
  justify-content: right;
  margin: 3rem;
}

.floors {
  margin-top: 1rem;
}

.simple-line {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 3rem;
}

@media only screen and (max-width: 700px) {
  .settings-upper-container {
    flex-direction: column;
  }

  .settings-order-container {
    width: 90%;
  }

  .settings-buttons {
    justify-content: center;
  }
}
