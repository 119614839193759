@import "../../assets/variables";

@mixin selection-disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.object-container {
  @include selection-disabled;

  display: flex;
  background-color: white;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 5px 2px 5px;
  border-radius: 0.75rem;
  border: 0.5px solid black;
  height: 1.5rem;
  min-height: 1.5rem;
  overflow: hidden;
  cursor: pointer;

  .object-icon {
    color: $color-black;
  }

  .object-icon:hover {
    color: $color-black;
  }

  .object-label {
    font-size: 0.5rem;
  }

  .object-size-text {
    font-size: 0.5rem;
  }

  .object-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    max-width: fit-content;
    max-height: fit-content;
    object-fit: contain;
    pointer-events: none;
  }
}

.selected {
  border: 0.5px solid $color-dark-green !important;
}

.canvas-object {
  justify-content: center;
  align-items: center;
  padding: 0px;
}
