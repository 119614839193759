#select-team {
  position: relative;
  border-radius: 0.4rem;
  box-shadow: 0rem 0.3rem 0.7rem rgba(0, 0, 0, 0.161);
  margin-top: 0.7rem;
  width: 100%;
}
#view-all-btn {
  cursor: pointer;
  color: $color-light-green;
  text-align: center;
  font-family: "Open Sans";
  font-size: 100%;
  text-transform: none;
}
#menu-open-top {
  top: auto;
  bottom: 100%;
}
