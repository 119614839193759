.modal {
  height: 100%;
  width: 25%;
  background-color: white;
  position: fixed;
  right: 0;
}

.close-btn {
  border: none;
  background-color: white;
  margin-top: 2rem;
  margin-left: 5rem;
}

.close-btn:hover {
  color: $color-light-red;
}

.form {
  width: 100%;
  height: 100%;
}

.modal-title-container {
  display: flex;
  flex-direction: row;
}

.modal-title {
  text-align-last: left;
  padding-left: 2rem;
}

.reservation-details {
  text-align: left;
  font-weight: bold;
  padding: 1rem 0 1rem 2rem;
}

.build-modal,
.floor-modal,
.desk-modal {
  text-align: left;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  width: 19rem;

  &.build-modal {
    background: white url("../../assets/images/building.png") 10% 50% no-repeat padding-box;
  }

  &.floor-modal {
    background: white url("../../assets/images/floor.png") 10% 50% no-repeat padding-box;
  }

  &.desk-modal {
    background: white url("../../assets/images/desk.png") 10% 50% no-repeat padding-box;
  }

  &.Mui-disabled {
    background-color: $color-grey-disabled;
  }
}

.MuiInputBase-root-MuiOutlinedInput-root {
  margin-left: 2rem;
  width: 19rem;
  margin-bottom: 1rem;
}

#input-desk {
  padding: 0;
}

.MuiOutlinedInput-root > #input-desk {
  padding: 0;
}

.dates-form-container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 1.275rem;
  gap: 0.2rem;
}

.modal-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

// .css-f7iyql {
//   background-color: $color-light-green;
//   color: $background-color-white;
//   border-top-left-radius: 0.3rem;
//   border-top-right-radius: 0.3rem;
// }

.css-1n2mv2k {
  background-color: $color-light-green;
}

span.MuiTypography-root.MuiTypography-caption {
  color: $background-color-white;
}

button.Mui-selected.MuiButtonBase-root.MuiDateRangePickerDay-day.MuiPickersDay-root {
  background-color: $color-light-green;
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiDateRangePickerDay-day.MuiDateRangePickerDay-dayOutsideRangeInterval {
  background-color: $color-light-green;
}

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgba(179, 222, 129, 0.6);
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightStart.MuiDateRangePickerDay-root {
  background-color: rgba(179, 222, 129, 0.6);
}

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
  background-color: rgba(179, 222, 129, 0.6);
}

.reservedDays {
  font-size: small;
}

.busyDays {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto auto auto auto auto;
}

.busyDays > span {
  align-content: center;
  margin: 0 4px 0 4px;
  color: red;
}

.MuiButtonBase-root .MuiSvgIcon-root:hover {
  color: $subtitle-color;
}
