@import "../../assets/variables";

.placed-element {
  display: flex;
  align-items: center;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.window-object {
  position: absolute;
  pointer-events: all !important;
  z-index: 1;
}

.floor-object {
  width: 100%;
  height: 100%;
  position: relative !important;
  pointer-events: none;
}

.custom-element {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.active-custom {
  border: 1px solid $color-dark-green !important;
}

.content-container {
  z-index: 1;
}

.hidden-toolbar {
  display: none !important;
}

.hidden-scrollbar {
  overflow: visible;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
  overflow: hidden !important;
}
