@import "../../assets/_variables.scss";

$toolbar-width: 400px;
$transition-time: 0.4s;
$icon-size: 1.2rem;
$spacing-value: 0.5rem;
$spacing-value-small: calc($spacing-value / 2);

.toolbar-container {
  position: absolute;
  z-index: 2;
  background-color: white url("../../assets/images/floor.png") 10% 50% no-repeat padding-box;

  .objects-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    overflow: scroll;
    gap: $spacing-value;
    padding: $spacing-value;
    transition: $transition-time;
    max-height: 50vh;
    z-index: 2;

    .pointer-tool-container {
      user-select: none;
      width: fit-content;

      .cursor-image-icon {
        height: $icon-size;
        width: $icon-size;
        cursor: pointer;
        margin-right: $spacing-value;
      }
    }
  }

  .toolbar-titlebar {
    height: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 0.5px gray solid;

    .titlebar-icon {
      margin-right: $spacing-value;
      font-size: 1rem;
      cursor: pointer;
    }

    .titlebar-icon:hover {
      color: black;
    }

    .toolbar-minimized {
      transform: rotate(180deg);
      transition: 200ms ease-in-out;
    }

    .toolbar-open {
      transition: 200ms ease-in-out;
    }
  }

  .toolbar-category {
    text-align: start;
    font-weight: bold;
  }

  .minimized {
    padding-top: 0px;
    padding-bottom: 0px;
    transition: $transition-time;
    max-height: 0px;
  }

  .default-tool-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-value-small;
    cursor: pointer;
    user-select: none;
  }

  .tool-icon:hover {
    color: $color-dark-green;
  }

  .toolbar-line-control-wrapper {
    margin-top: $spacing-value-small;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .toolbar-upper-line-control-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-value;
  }

  .toolbar-color-picker {
    justify-content: center;
    margin-top: $spacing-value;
    width: 6rem !important;
  }

  .chosen-toolbar-color {
    width: 1.5rem;
    height: 1.5rem;
  }

  .move-control-container {
    margin-bottom: $spacing-value;

    .toolbar-unit-dropdown-wrapper {
      margin-top: $spacing-value;
    }

    .arrow-icon {
      border: 1px solid gray;
      height: $icon-size;
      width: $icon-size;
    }

    .arrow-icon:hover {
      color: $color-dark-green;
      cursor: pointer;
    }

    .arrow-icon-up {
      transform: rotate(90deg);
    }

    .arrow-icon-right {
      transform: rotate(180deg);
    }

    .arrow-icon-down {
      transform: rotate(-90deg);
    }

    .horizontal-arrows-container {
      display: flex;
      gap: 1.6rem;
      justify-content: center;
      margin-bottom: 3px;
    }
  }

  .default-tools-undo-redo-container {
    margin: auto;
    display: flex;
    gap: 1rem;
  }
}

.toolbar-default-items-dialog-buttons-container {
  display: flex;
  margin-left: auto;
  margin-bottom: 1rem;
}
.tool-disabled {
  color: gray;
  pointer-events: none;
  cursor: default !important;
}
