@import "../../assets/variables";

$spacing-value-small: 1rem;
$spacing-value-medium: 2rem;
$image-size: 2rem;

.toolbar-manager-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .surfaces-container {
      display: flex;
      gap: $spacing-value-small;
      width: 50%;

      .form-surface {
        width: 50%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: $spacing-value-medium;

        .form-wrapper {
          display: flex;
          flex-direction: column;
          gap: $spacing-value-small;
          align-self: center;
          width: 20rem;

          .size-dropdowns-container {
            display: flex;
            width: 100%;
            gap: $spacing-value-medium;
            justify-content: space-between;

            .size-dropdown {
              width: 100%;
            }
          }
        }
      }
    }

    .left-surfaces-wrapper {
      display: flex;
      width: 60%;
      flex-direction: column;
      gap: $spacing-value-small;

      .upload-photo-buttons-surface {
        display: flex;
        justify-content: space-between;
        gap: $spacing-value-small;
        padding: $spacing-value-small;

        .upload-image-button-container {
          display: flex;
          flex-direction: column;
          text-align: initial;
          gap: $spacing-value-small;
          width: 50%;

          .upload-image-button-lower-container {
            display: flex;
            flex-direction: row;
            gap: $spacing-value-small;
            height: 3rem;
            align-items: center;

            .toolbar-default-image-container {
              position: relative;
            }
          }
        }
      }

      .toolbar-added-items-surface {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
        height: 100%;
        flex-flow: wrap;
        gap: $spacing-value-small;
        padding: $spacing-value-medium;
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }

  .hidden {
    display: none;
  }

  .form-button {
    background-color: $color-dark-green;
    border-color: $color-dark-green;
    color: white;
    text-transform: none;
    height: 3rem;
  }

  .form-button:hover {
    border-color: $color-dark-green;
    background-color: white;
    color: $color-dark-green;
  }

  .submit-button {
    background-color: $color-dark-orange;
    border-color: $color-dark-orange;
    color: white;
    text-transform: none;
    margin-top: $spacing-value-small;
  }

  .submit-button:hover {
    background-color: white;
    border-color: $color-dark-orange;
    color: $color-dark-orange;
  }
}
