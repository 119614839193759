.title-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 1.15rem 1.15rem 1.15rem 2.3rem;

  h1 {
    font: $font-style-medium;
    color: $color-active-light;
    font-size: 2.125rem;
  }

  .add-team {
    text-decoration: inherit;
    color: $color-light-green;
    font-size: 1rem;
    margin-left: 1.875rem;
  }

  .add-team:hover {
    color: $color-dark-green;
  }
}

.teams-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  width: calc(100% - 2.5rem);
  overflow: auto;
}

.team-container {
  width: 25rem;
  margin: 0.6rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 0.938rem;
      margin: 0;
      color: $color-active-light;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .right {
      display: flex;
      flex-direction: row;
    }
  }

  h3 {
    text-align: start;
    margin: 0;
    font-size: 1.25rem;
    color: $title-color;
    cursor: pointer;
  }
}

.icon-wrapper {
  &-first {
    display: flex;
    flex-direction: row;
    margin-right: 1.25rem;
  }
}

@media only screen and (max-width: 20rem) {
  .title-container .add-team {
    margin-left: 0;
  }

  .title-container h1 {
    font-size: 1.3rem;
  }

  .icon-wrapper-first {
    margin-right: 0;
  }

  .team-container h3 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 3.1rem;
  }

  .teams-list {
    padding-left: 1.65rem;
  }
}

@media only screen and (max-width: 40rem) {
  .team-container h3 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 3.1rem;
  }
}

@media only screen and (max-width: 55.25rem) {
  .teams-list {
    display: flex;
    justify-content: center;
  }
}
