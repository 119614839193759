.assignment-container {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: auto 2fr 1fr auto;

  .assignment-data {
    .desk-container {
      font: $font-style-medium-bold;
      text-align: left;
      color: $color-active;
      padding-bottom: 0.5rem;
      margin-right: 2rem;
    }

    .assignment-team {
      text-align: left;
      color: $color-active-light;
      padding-bottom: 0.5rem;
      font-weight: 450;
    }
    .assignment-location {
      text-align: left;
      color: $color-light-gray;
      padding-bottom: 0.5rem;
    }

    .assignment-date {
      text-align: left;
      padding-bottom: 0.5rem;
    }
  }

  .hours-container {
    color: $color-light-red;
    text-align: right;
    padding-right: 0.5rem;
  }

  .selectAll-checkbox {
    width: min-content;
  }
}

.delete-button-reservation {
  color: $color-light-red;
  padding-left: 1.25rem;
  padding-top: 0;
}

.delete-button-reservation:hover {
  color: $color-red;
  cursor: pointer;
}

.select-button {
  padding: 0.5rem;
  cursor: pointer;
  color: $color-light-green;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 90%;
  border: transparent;
  background-color: transparent;
}
.select-button:hover {
  background-color: $color-lightest-gray;
}

.delete-selection-button {
  padding: 0.5rem;
  cursor: pointer;
  color: $color-light-red;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 90%;
  border: transparent;
  background-color: transparent;
}
.delete-selection-button:hover {
  background-color: $color-lightest-gray;
}

.selectAll-checkbox {
  cursor: pointer;
  color: $color-gray;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 90%;
}
