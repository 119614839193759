.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5rem;
  padding-top: 6rem;
  padding-left: 5rem;
  width: calc(100% - 10rem);
}

.login-page {
  background-image: url("../../assets/images/background.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
}

.image-container {
  width: 2.813rem;
  height: 2.875rem;
}

.login-title-container {
  align-self: center;
  text-align: left;
  font: $font-style-big;
  color: $text-color;
  margin: 0;
}

.input-icons {
  display: flex;
  flex-direction: column;
}

.icon {
  padding: 1rem;
  color: $title-color;
  min-width: 3.125rem;
  text-align: center;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  background-color: $background-color-white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: $box-shadow-dark;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  padding: 2rem;
}
.button-container {
  width: 8.3rem;
  height: 2.125rem;
  background: $color-dark-green 0% 0% no-repeat padding-box;
  border: 0.0625rem solid $color-light-green;
  border-radius: $border-radius-button;
  text-align: center;
  color: $background-color-white;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: bold;
}

.button-container:hover {
  box-shadow: 0 0.275rem 0.275rem $color-light-green;
  border: 0.0625rem solid $color-dark-green;
  border-radius: $border-radius-button;
}

.login-button-container {
  width: 100%;
  background: $color-light-green 0% 0% no-repeat padding-box;
  border: 0.0625rem solid $color-light-green;
  border-radius: $border-radius-button;
  text-align: center;
  color: $background-color-white;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: bold;
  padding: 0.675rem;
}

.login-button-container:hover {
  background: $color-dark-green 0% 0% no-repeat padding-box;
  border: 0.0625rem solid $color-dark-green;
  border-radius: $border-radius-button;
}

.alert-container {
  font: $font-style-small;
  color: $text-color;
}

@media only screen and (min-device-width: 20rem) and (max-device-width: 38rem) {
  .login-form-container {
    align-items: center;
  }
  .input-icons {
    max-width: 15.7rem;
    height: 2.5rem;
  }
  .login-wrapper {
    gap: 5rem;
    padding-top: 6rem;
    padding-left: 1.5rem;
    width: calc(100% - 2rem);
    align-items: center;
  }
}
@media only screen and (min-width: 38rem) {
  .input-icons .MuiTextField-root {
    width: 20rem;
  }
}

.login-icon {
  pointer-events: none;
}

.input-textfield-login input:read-write:hover + fieldset {
  border-width: 0.0625rem;
  border-color: $color-light-green;
  box-shadow: 0 0 0.0625rem $color-light-green;
}

.input-textfield-login input {
  padding: 0.6rem;
}

.input-textfield-login input + fieldset {
  border-radius: $border-radius;
}

.input-textfield-login fieldset {
  border-radius: $border-radius;
}

.input-textfield-login .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: $color-light-green;
  border-width: 0.0625rem;
}

.MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $color-light-green;
  border-width: 0.0625rem;
}

.input-icons .MuiTextField-root {
  max-width: 20rem;
}

.left-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 40rem) {
  .input-textfield-login {
    width: 17.5rem;
  }

  #loginLogo {
    align-self: flex-start;
  }
}
