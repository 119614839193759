@import ".././../assets/variables";

.desk-wrapper {
  cursor: pointer;
  z-index: 1;

  .canvas-desk-container {
    display: flex;
    border-radius: 10px;
    z-index: 2;
    flex-direction: column;

    &.selected {
      color: black !important;
    }

    .desk-image {
      display: flex;
      justify-content: center;
      position: relative;
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  }
}

.desk-availability-bubble {
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  margin: auto;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
