@import url("https://fonts.googleapis.com/css?family=Open+Sans");

$font-style-small: normal normal normal 0.875rem/1.1875rem Open Sans;
$font-style-small-bold: normal normal bold 0.875rem/1.1875rem Open Sans;
$font-style-big: normal normal 3rem/3.6875rem Open Sans;
$font-style-medium: normal normal normal 1rem/1.375rem Open Sans;
$font-style-medium-bold: normal normal bold 1rem/1.375rem Open Sans;
$font-style-navbar-title: normal normal bold 1.625rem/2.25rem Open Sans;
$font-style-calendar-title: normal normal normal 1.625rem/2.25rem Open Sans;
$font-style-menu-title: normal normal 600 0.625rem/0.875rem Open Sans;

$background-color-white: #ffffff;
$box-shadow-dark: 0 0.1875rem 0.375rem #00000029;
$border-color-grey: #eaeaea;
$border-radius: 0.5rem;
$border-radius-button: 0.375rem;

$text-color: #333333;
$dark-gray: #363535;
$title-color: #404040;
$subtitle-color: #aaa7a7;
$color-black: #000000;
$color-dark-orange: #ff9100;
$color-light-green: #8bc34a;
$color-dark-green: #67a71d;
$color-yellow: rgb(255, 255, 0);
$color-red: #e22b2b;
$color-very-light-red: #fd9d9d;
$color-orange: #fbc93e;
$color-purple: #9900cc;
$color-light-red: #ec5555;
$color-hover-white: #fffcfc;
$color-red-faded: #ed6b3e;
$color-gray: #404040;
$color-grey-disabled: #eaeaea;
$color-grey-disabled-text: #abadae;
$color-active-light: #707070;
$color-active: #666666;
$border-color-darker-gray: #c0c0c0;
$color-light-gray: #aaaaaa;
$color-faded-gray: #a3a3a3;
$color-lightest-gray: #efefef;
$color-very-light-gray: #dddddd;
$color-darker-gray: #9d9b9b;
$background-calendar-color: #fafafa;
$background-draggable: #f6f5f5;

$current-date-color: #ffff76;
$selected-date-color: #ffffa9;
$background-date-color: #e6e6e6;
$background-disabled-date-color: #f0f0f0;

$gridSize: 1.875rem;
