@import "../../assets/variables";

.object {
  position: absolute;
  z-index: 1;
  text-align: unset;
  top: 0px;
  left: 0px;
}

.hoverObject {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.item-label-text {
  display: flex;
  justify-content: center;
}

.drag {
  position: absolute;
  user-select: none;

  & img {
    position: absolute;
  }
}

.container1 {
  width: max-content;
  height: max-content;
}

.layout-container {
  min-height: 100vh !important;
  min-width: 100vw;
  position: relative !important;
}

.edit-mode {
  background-image: repeating-linear-gradient(#eee 0 1px, transparent 0px 100%), repeating-linear-gradient(90deg, #eee 0 1px, transparent 0px 100%);
  background-size: $gridSize $gridSize;
}

.container-parent {
  height: 100vh;
}

.container-buttons {
  position: absolute;
  width: 100%;
  z-index: 1;

  .buttons-wrapper {
    display: flex;
    gap: 1rem;
    margin: auto;
    justify-content: center;
  }
}

.editMode {
  & .drag:hover {
    cursor: grab;
  }

  & .react-draggable-dragging {
    cursor: grabbing !important;
  }

  .react-draggable-dragging {
    color: black;
  }
}

.userMode {
  & .tool {
    visibility: hidden;
    pointer-events: none;
  }
}

.navbar-header-canvas {
  position: fixed !important;
  background-color: white;
}

.wayfare-button {
  background-color: $color-dark-green;
  border-width: 0px;
  color: white;
  border-radius: 0.2rem;
  padding: 0.25rem;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .container-buttons {
    display: none;
  }

  .container1 {
    margin-top: -1rem;
  }

  .container-parent {
    max-width: 100vw;
  }

  .react-grid-layout {
    position: relative !important;
    overflow: scroll;
  }
}

.rotate-cursor {
  cursor: url("../../assets//images/rotate-cursor.png"), auto;
}

.flip-cursor {
  cursor: url("../../assets//images/swap-vertical-cursor.png"), auto;
}

.pointer-cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}
