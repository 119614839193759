.input-with-icon-textfield-adminBoard input:read-write:hover + fieldset {
  border-width: 0.0625rem;
  border-radius: 0.5rem;
  color: #656565 !important;
}

.input-with-icon-textfield-adminBoard input {
  padding: 0.5rem;
  width: 65vw;
  border-width: 0.0625rem;
  border-radius: 0.5rem;
  color: #656565 !important;
}

.input-with-icon-textfield-adminBoard input:disabled + fieldset {
  box-shadow: none;
  border-radius: 0.5rem;
  border-width: 0.0625rem;
}

.input-with-icon-textfield-adminBoard fieldset {
  border-radius: 0.5rem;
  border-width: 0.0625rem;
}

.icons-container-mobile-adminDash {
  display: inline-flex;
  width: inherit;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.input-with-icon-textfield-adminBoard {
  width: 12rem;
  box-shadow: $box-shadow-dark;
  border-width: 0.0625rem;
  border-radius: 0.5rem;
  margin-right: 4rem;
}

.input-with-icon-textfield-adminBoard .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0.0625rem;
  border-radius: 0.5rem;
}

.calendar-textfield {
  margin-right: 1.5em;
  border-radius: 0.5rem !important;
  background: $background-color-white;
}

@media only screen and (max-width: 700px) {
  .input-with-icon-textfield-adminBoard {
    display: auto;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .input-with-icon-textfield-adminBoard {
    width: 90vw;
    margin-left: 0.5rem;
    display: inline-flex;
  }

  .calendar-textfield {
    height: 2.5rem;
    width: 100%;
    padding-bottom: 0.1rem;
    display: inline-flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0.1rem 0.2rem 0.5rem;
  }

  .calendar-textfield {
    padding-left: 1.1rem;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
    box-shadow: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .input-with-icon-textfield-adminBoard {
    width: 100%;
    box-shadow: $box-shadow-dark;
    border-radius: 0.5rem;
    margin-right: 4rem;
  }
  .calendar-textfield {
    height: 2.5rem;
    width: 100%;
    padding-bottom: 0.1rem;
    display: inline-flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0.1rem 0.2rem 0.5rem;
  }

  .calendar-textfield {
    padding-left: 1.1rem;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
    box-shadow: none !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-with-icon-textfield-adminBoard input {
    padding: 0.5rem;
    width: 61vw;
    border-width: 0.0625rem;
    border-radius: 0.5rem;
    color: #656565 !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .input-with-icon-textfield-adminBoard input {
    padding: 0.5rem;
    width: 65vw;
    border-width: 0.0625rem;
    border-radius: 0.5rem;
    color: #656565 !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .input-with-icon-textfield-adminBoard input {
    padding: 0.55rem;
    width: 66vw;
    border-width: 0.0625rem;
    border-radius: 0.5rem;
    color: #656565 !important;
  }
}

@media only screen and (min-width: 500px) and (max-width: 700px) {
  .input-with-icon-textfield-adminBoard input {
    padding: 0.55rem;
    width: 72vw;
    border-width: 0.0625rem;
    border-radius: 0.5rem;
    color: #656565 !important;
  }
}
