.content-label {
  font-weight: 700;
  font-size: 1rem;
  margin-top: 1.5rem;
  text-align: start;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
}
.profile-form-container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2375rem;
}

#refresh-button {
  cursor: pointer;
  color: $color-light-green;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 100%;
}

#refresh-button:hover {
  color: $color-dark-green;
  background-color: transparent;
}

.input-with-icon-textfield input:read-write:hover + fieldset {
  border-width: 0.0625rem;
  border-color: $color-light-green;
  box-shadow: 0 0 0.0625rem $color-light-green;
}

.input-with-icon-textfield input {
  padding: 0.6rem;
}

.input-with-icon-textfield input:disabled + fieldset {
  box-shadow: none;
}

.input-with-icon-textfield {
  width: 20rem;
  box-shadow: $box-shadow-dark;
}

.MuiInputLabel-root {
  background-color: $background-color-white;
  z-index: 2;
}

.invalid fieldset {
  border-color: red;
  box-shadow: 0 0 0.0625rem red;
}

.input-with-icon-textfield .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0.0625rem;
  border-color: $color-light-green;
  box-shadow: 0 0 0.0625rem $color-light-green;
}

#select-textfield + .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0.0625rem;
  border-color: $color-light-green;
  box-shadow: 0 0 0.0625rem $color-light-green;
}

#select-textfield + .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0.0625rem;
  border-color: $color-light-green;
  box-shadow: 0 0 0.0625rem $color-light-green;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0.0625rem !important;
  border-color: $color-light-green !important;
}
