@import "~normalize.css/normalize";
@import "react-toastify/dist/ReactToastify.css";

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root,
.App {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &.ee-enabled,
  &.ee-enabled * {
    cursor: url("../images/surprize.cur"), auto !important;
  }
}

@import "../variables";
@import "../../components/components";
@import "../../pages/pages";
