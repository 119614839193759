@import "../../../assets/variables";

.navbar-info-container {
  margin: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: justify;

  .navbar-info-icon {
    color: $color-dark-green;
    cursor: help;
  }
  .navbar-info-content {
    display: none;
    position: absolute;
    list-style-type: none;
    box-shadow: $box-shadow-dark;
    flex-direction: column;
    background-color: white;
    transform: translate(calc(-50% + 0.75rem), 0);
    border-radius: 1rem;
    padding: 1rem;
    top: 4rem;
    gap: 1rem;

    .navbar-info-list-item {
      display: flex;
      gap: 1rem;

      .info-list-bubble {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
      }
      .info-list-text {
        font-weight: 500;
      }
    }
  }
}

.navbar-info-container:hover .navbar-info-content {
  display: flex;
}

@media only screen and (max-width: 700px) {
  .navbar-info-container {
    display: none;
  }
}
@media only screen and (max-width: 1560px) {
  .navbar-info-content {
    top: 7rem !important;
  }
}
