.error-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  max-width: 20rem;
  z-index: 15;

  border: 0.0625rem solid $border-color-darker-gray;
  border-radius: 0.25rem;
  box-shadow: $box-shadow-dark;
  background: $background-color-white;

  position: absolute;
  top: 4.5rem;
  left: 45%;

  font: $font-style-medium-bold;

  .close-error {
    color: $color-red;
    cursor: pointer;
    padding: 0 0.25rem;
    margin-left: 1rem;
  }
}
