.no-button,
.yes-button {
  border: none;
  font: inherit;
  border-radius: 0.4rem;
  cursor: pointer;
  width: 5rem;
}

.yes-button:hover {
  background-color: $color-light-green;
  color: $color-hover-white;
}

.no-button:hover {
  background-color: $color-light-green;
  color: $color-hover-white;
}

h2.MuiTypography-root.MuiDialogTitle-root {
  color: $color-light-green;
  font-size: bold;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  border-bottom: 0.1rem solid $color-light-green;
  margin-bottom: 1rem;
}
