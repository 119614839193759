.content {
  text-align: left;

  .btn-container {
    flex-direction: row;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .back-button,
  .update-button {
    height: 3rem;
    width: 10rem;
    font: $font-style-medium-bold;
    color: white;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    background-color: $color-light-green;
  }

  .back-button {
    margin: 0.5rem 2rem 0 4rem;
  }

  .update-button {
    justify-self: right;
    margin: 0.5rem 4rem 0 4rem;
  }

  .update-button:hover {
    background-color: $subtitle-color;
    color: $border-color-grey;
  }
}

.informations {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.update-container {
  display: flex;
  place-content: space-between;

  .info-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 5rem 0rem 4rem;
    width: 70%;

    .info-title {
      text-align-last: left;
      font: $font-style-navbar-title;
      padding-bottom: 2rem;
    }

    .personal-data {
      display: grid;
      grid-template-columns: 1fr 1.9fr;
      padding: 0 0rem 2rem 0rem;
      text-align-last: left;

      .personal-data-title {
        font: $font-style-medium-bold;
        align-self: center;
      }

      .input-container {
        width: 100%;
      }
    }
  }
}

.permissions-container {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;

  .droppable-content {
    display: flex;
    height: 100%;
    padding: 0 2rem 0 0rem;
    width: -webkit-fill-available;
  }

  .all-groups {
    margin-right: 2rem;
  }

  .all-groups,
  .user-groups {
    //width: 20rem;
    width: inherit;
    display: flex;
    font-size: 20px;
    background-color: white;
    flex-direction: column;
    padding: 0.5rem 0rem 0.5rem 1rem;
    border: 1px solid $subtitle-color;
    box-shadow: $box-shadow-dark;
    border-radius: $border-radius;
    overflow-y: auto;

    .title-groups {
      font: $font-style-medium-bold;
      font-size: 1.3rem;
      padding: 2rem;
      align-self: center;
    }

    .item-container {
      cursor: pointer;
      background-color: $background-color-white;
      border: 1px solid $subtitle-color;
      border-radius: $border-radius;
      box-shadow: $box-shadow-dark;
      padding: 0.9rem;
      margin: 0.2rem 2.5rem;
      font: $font-style-medium;
      font-size: 1.1rem;
      text-align: center;
    }

    .item-container:last-child {
      margin-bottom: 1.5rem;
    }

    .item-container:hover {
      border: 1px solid $color-light-green;
    }
  }
}

.content-label {
  // padding-bottom: 2rem;
  font: normal normal bold 1rem/1.375rem Open Sans;
}

.input-with-icon-textfield input:read-write:hover + fieldset {
  border-width: 0.0625rem;
  border-color: $color-light-green;
  box-shadow: 0 0 0.0625rem $color-light-green;
}

.error-msg {
  color: $color-red;
}

@media only screen and (max-width: 700px) {
  .content {
    .back-button,
    .update-button {
      margin: 0.5rem 0rem 0 0.5rem;
      width: 10rem;
      height: 2rem;
    }

    .update-button {
      margin-right: 1rem;
    }
  }

  .informations {
    display: flex;
    flex-direction: column;
    padding: 0 0.6rem 0 0.6rem;

    .info-container {
      padding: 0rem 0.4rem 0rem 0.4rem;
      width: 100%;

      .personal-data {
        grid-template-columns: 0.5fr 1fr;

        .input-with-icon-textfield {
          max-width: fit-content;
        }
      }
    }
  }

  .permissions-container {
    padding: 0 0.4rem 0 0.4rem;
    margin-right: 0;

    .droppable-content {
      padding: 0;
    }

    .all-groups {
      margin: 0 0.5rem 0 0rem;
    }

    .all-groups,
    .user-groups {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;

      .title-groups {
        padding: 0.5rem;
        font-size: 1rem;
      }

      .item-container {
        margin: 0.4rem 0.4rem 0.8rem 0.4rem;
        padding: 0.4rem;
        font-size: 0.8rem;
      }
    }
  }

  .content-label {
    align-self: center;
  }
}
