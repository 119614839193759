.iconLabel {
  display: flex;
  align-items: center;
  font: $font-style-small-bold;
  user-select: none;
  cursor: pointer;
  margin-right: 5px;

  .iconLabel-icon {
    padding-right: 7px;
  }

  &.green {
    color: $color-dark-green;
  }

  &.red {
    color: $color-red;
  }

  &.yellow {
    color: $color-yellow;
  }
}

@media only screen and (max-width: 20rem) {
  .iconLabel .iconLabel-icon {
    margin-right: 0.3rem;
  }
}
