@import "../../assets/variables";

.navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0.125rem;
  box-sizing: border-box;
  z-index: 2;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding-left: 4rem;
}

.nav-list {
  list-style-type: none;
  color: $color-light-green;
  padding: 0.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.nav-list-profile {
  padding: 0.25rem;
  text-decoration: none;
  list-style-type: none;
}

.new-reservation {
  list-style-type: none;
  margin-left: 2.125rem;
  color: $color-light-green;
  padding: 0.625rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.nav-list:hover,
.nav-list:active {
  color: $background-color-white;
}

.left-container-navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.right-container-navbar {
  display: inline-flex;
  padding-right: 5rem;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-container-adminDash {
  display: flex;
  align-items: center;
}

.calendar-components {
  z-index: 10;
  border-radius: 0.8rem;
  color: $background-color-white;
  margin: 0 1rem 0 2rem;
  cursor: pointer;
}

.logo-calendar {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.react-calendar {
  position: absolute;
  max-width: 13rem;
  background: $background-color-white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125rem;
  height: 18rem;
  border: 0.0625rem solid $border-color-grey;
  border-radius: 1rem;
  margin-left: -0.5rem;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 2rem;
  background-color: $color-light-green;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

.react-calendar__navigation button {
  background: none;
}

.react-calendar__navigation__arrow {
  color: $background-color-white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.react-calendar__navigation__label__labelText {
  font-size: 0.9rem;
  color: $background-color-white;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5rem;
  color: $color-black;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: bold;
}

.react-calendar__month-view__days__day {
  flex: 0 0 10.2857%;
  overflow: hidden;
}

abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: $color-light-gray;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 0.625rem 0.417rem;
  background: none;
  text-align: center;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.react-calendar__tile:disabled {
  background-color: $background-disabled-date-color;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: $background-date-color;
  height: 2rem;
}

.react-calendar__tile--now {
  background: $current-date-color;
  height: 2rem;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $selected-date-color;
}

.react-calendar__tile--active {
  background: $color-light-green;
  color: $background-color-white;
  height: 2rem;
  font-weight: 600;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $color-dark-green;
}

.MuiSvgIcon-root:hover {
  color: $color-red;
}

.icons-container {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 700px) {
  .dropdown-container-adminDash {
    margin-left: -2rem;
  }
}

@media only screen and (max-width: 1560px) and (min-width: 700px) {
  .dropdown-container-adminDash {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .dropdown-container-adminDash {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .left-container-navbar {
    display: flex;
    flex-direction: column;

    .logo,
    .calendar-components {
      margin-bottom: 1rem;
    }
  }
  .right-container-navbar {
    padding-left: 1rem;
    width: 100%;
    padding-right: 0;
    justify-content: center;
  }

  .navbar-header {
    padding-left: 0;
  }

  .hidden {
    display: none;
  }

  .logo-calendar {
    width: inherit;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 20rem) {
  .nav-list {
    margin-left: 0;
  }
}

.text-link .MuiSvgIcon-root:hover {
  color: $color-light-green;
  cursor: pointer;
}

.greeting-container {
  display: none;
  align-items: center;
  font: $font-style-medium;
  padding: 0.25rem;
  margin-top: 0.25rem;

  .name {
    font: $font-style-medium-bold;
  }
}

.active {
  color: $color-light-green !important;
}

.text-link {
  color: $color-light-gray;
}

@media only screen and (max-width: 1400px) and (min-width: 700px) {
  .dropdown-container-adminDash {
    scroll-padding-top: 0.2rem;
  }
}

@media only screen and (max-width: 700px) {
  .mobile-restricted {
    display: none;
  }
}
