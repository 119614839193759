.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.iconLabel-container {
  right: 15px;
  position: absolute;
  z-index: 1;
}

.edit-menus-container {
  max-height: 551px;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 15vh;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  // padding: 1.5em 0em;
}

// .menu-container {
//   padding-right: 1.5em;
// }

.menu-deskContainer {
  padding: 1.5em;
  background-color: #fff3;
  backdrop-filter: blur(5px);
  .form-container {
    margin-top: 0;
  }
}
