.avatar-container {
  height: 2.25rem;
  width: 2.25rem;
  padding-top: 0.25rem;
  margin-left: 0.25rem;
  cursor: pointer;

  .avatar,
  .placeholder {
    min-width: 2.25rem;
    min-height: 2.25rem;
    max-width: 2.25rem;
    max-height: 2.25rem;
    border-radius: 50%;
    border: solid 0.2rem $color-light-green;
    box-shadow: rgba(0, 0, 0, 0.25) 0.1rem 0.2rem 0.5rem;
  }
}

#avatar-tooltip[data-show] {
  display: flex;
}

#avatar-tooltip {
  color: $color-light-gray;
  display: none;
  align-items: center;
  justify-content: space-around;
  border: 0.0625rem solid $border-color-grey;
  box-shadow: $box-shadow-dark;
  border-radius: $border-radius;
  background: $background-color-white;
  height: 7rem;
  width: 10rem;
  z-index: 5;
  position: relative;
  cursor: default;

  .avatar,
  .placeholder {
    object-fit: cover;
    min-width: 2.25rem;
    min-width: 2.25rem;
    max-width: 2.25rem;
    max-height: 2.25rem;
    border-radius: 50%;
  }

  .right-container-avatar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .logout {
    margin-top: 0.4rem;
    font: $font-style-medium;
    cursor: pointer;
  }

  .profile {
    margin-top: 0.4rem;
    font: $font-style-medium;
    cursor: pointer;
  }

  hr {
    margin: 0.5rem 0 0 0;
    width: 100%;
    height: 1px;
    border: 0;
    background-image: linear-gradient(to right, $background-color-white, $color-darker-gray, $background-color-white);
    background-color: $color-grey-disabled;
  }
}
