.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0.125rem;
    box-sizing: border-box;

    .left-container {
      display: flex;
      align-items: center;
      margin-left: 0.625rem;

      .name {
        color: white;
        font: $font-style-navbar-title;
        margin-left: 0.625rem;
      }

      .logo {
        margin-right: 1rem;
      }
    }

    .right-container {
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 700px) {
    .dashboard-content {
      padding: 0.5rem 2rem !important;
    }
  }

  .dashboard-content {
    // padding: 1.25rem 4rem;
    padding: 0 4rem;
    box-sizing: border-box;
    height: 100px;
    width: 100vw;

    .content-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .dropdown-container {
        display: flex;
        margin-bottom: 3.5rem;
      }
    }

    .canvas-with-edit-container {
      width: fit-content;

      .iconLabel-container {
        position: absolute;
        right: 4.3125rem;
        top: 5.1875rem;

        .flexed-container {
          gap: 1.75rem;
          flex-direction: row;
          display: flex;
        }
      }

      @media only screen and (max-width: 700px) {
        .canvas-with-right-component-container {
          display: inline-block !important;
        }
      }

      .canvas-with-right-component-container {
        display: flex;

        .canvas-with-controls-container {
          // margin-right: 3.25rem;
        }

        .edit-menus-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          gap: 2rem;
          overflow-y: scroll;
          padding-right: 1rem;
          padding-left: 0.5rem;
        }

        .color-menu-container {
          width: fit-content;
          height: fit-content;
          position: absolute;
          right: 4.5rem;
          margin-top: 7.5rem;
        }
      }
    }
  }
}

#legend-btn {
  cursor: pointer;
  color: $color-light-green;
  font-size: 1.5rem;
  justify-content: start;
  height: 1rem;
  width: 1rem;
  right: 3em;
  z-index: 2;

  &:hover {
    color: $color-dark-green;
    background-color: transparent;
  }
}

#red-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: $color-red;
}

#green-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: $color-light-green;
}

#purple-circle {
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: $color-purple;
}

#black-circle {
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: $color-dark-orange;
}

#orange-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: $color-orange;
}

#grey-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: $color-light-gray;
}

.line {
  display: flex;
  flex-direction: row;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  text-align: start;
}

#tooltip-colors {
  padding-left: 0.625rem;
  display: none;
  align-items: center;
  justify-content: space-around;
  border: 0.0625rem solid $border-color-grey;
  box-shadow: $box-shadow-dark;
  border-radius: $border-radius;
  background: $background-color-white;
  height: 10.5rem;
  width: 23rem;
  z-index: 1;
  position: relative;
  cursor: default;
}

#tooltip-colors[data-show] {
  display: flex;
}

.legend {
  display: flex;
  position: absolute;
  z-index: 2;
  justify-content: start;
}

.copyright-wrapper {
  position: fixed;
  z-index: 1;
  bottom: 1rem;
  left: 1rem;
}
