.center-info {
  display: flex;
  justify-content: center;
}
.update-container .info-container.margins-info-container {
  padding: 1rem 1rem 1rem 1rem;
}

.MuiInputAdornment-root .MuiSvgIcon-root:hover {
  color: rgba(0, 0, 0, 0.54);
}
.update-button {
  padding: 0.5rem;
  font: $font-style-medium-bold;
  color: white;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  background-color: $color-light-green;
  text-align-last: center;
}

.update-container .info-container .personal-data-company {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  padding: 0 0rem 1.5rem 0rem;
  text-align-last: left;

  .personal-data-title {
    font: $font-style-medium-bold;
  }
}
