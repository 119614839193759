.list-wrapper {
  margin: 3.125rem auto;
}

.list {
  background: $background-color-white;
  border-radius: 0.32rem;
  list-style: none;
  padding: 0.625rem 1.25rem;
  width: 31rem;
}

.list-item-image {
  border-radius: 50%;
  width: 4rem;
}

h1 {
  margin: 0.625rem;
  font-size: 2.5rem;
  color: rgb(1, 1, 59);
}

ul li {
  font-size: 1.25rem;
}

.main {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 1.25rem;
}

.link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.active-users {
  font: $font-style-medium-bold;
  color: $text-color;
}

.register-link {
  text-decoration: inherit;
  color: $color-light-green;
}

.register-link:hover {
  color: $color-dark-green;
}

.edit-button {
  color: rgb(126, 126, 126);
  height: 1.5rem;
  position: absolute;
  right: 1rem;
}

.edit-button:hover {
  color: $color-faded-gray;
}

.edit-button .MuiSvgIcon-root:hover {
  color: $color-light-green;
}

.tooltiptext {
  font-size: 1rem;
}

.users-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  width: calc(100% - 2.5rem);
  height: calc(100vh - 13.3rem);
  overflow: auto;
}

.user-container {
  width: 25rem;
  margin: 0.6rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      font-size: 0.938rem;
      margin: 0;
      color: $color-active-light;
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .user-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0.625rem;
    }

    .right {
      display: flex;
      flex-direction: row;
    }
  }

  h3 {
    text-align: start;
    margin: 0;
    font-size: 1.25rem;
    color: $title-color;
  }
}

.list-item-image {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
}

.row {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 20rem) {
  .users-list {
    padding-left: 0;
    width: 100%;
  }

  .user-data {
    width: 7rem;
  }
}

@media only screen and (max-width: 55.25rem) {
  .users-list {
    display: flex;
    justify-content: center;
    height: auto;
  }
  .copyright-wrapper {
    bottom: 0.9rem;
    left: 0.2rem;
  }
}
