@import "../../assets/variables";

.floor-dropdown {
  margin-left: 0.25rem;
}

#new-reservation-btn {
  cursor: pointer;
  color: $color-light-green;
  text-align: center;
  font-family: "Open Sans";
  font-size: 100%;
  text-transform: none;
  margin-left: 1rem;

  &:hover {
    color: $color-dark-green;
    background-color: transparent;
  }

  &:disabled {
    color: $color-light-gray;
    cursor: not-allowed;
  }

  .text {
    padding-left: 0.5rem;
  }
}

.icons-container-mobile {
  display: inline-flex;
  width: inherit;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

#adminMode-btn {
  margin-left: 0.2rem;
}

.navbar-spinner {
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 1.25rem;
  color: $color-dark-green !important;
}

.icons-container-mobile {
  .dashboard {
    .css-1yjmaq3-MuiSvgIcon-root {
      left: 15.5rem;
      top: 5.5rem;
      padding-top: 0;
    }
  }
}

.navbar-save-canvas-button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.2rem;
  margin-left: 0.5rem;
  color: white !important;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0.4rem 0.2rem rgba(150, 150, 150, 0.25);
  box-shadow: 0px 0px 0.4rem 0.2rem rgba(150, 150, 150, 0.25);
  background-color: $color-dark-green;
}

@media only screen and (max-width: 700px) {
  .building-dropdown {
    width: 100vw;
    margin-left: 0.5rem;
    display: inline-flex;
  }

  .floor-dropdown {
    width: 100vw;
    margin-left: 0.5rem;
    display: inline-flex;
  }

  #new-reservation-btn {
    margin-left: 0.2rem;
  }

  #mobile-avatar {
    margin: 0;
  }

  .navbar-save-canvas-button {
    display: none !important;
  }

  .canvas-mode-dropdown {
    display: none !important;
  }
}
