.menu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // width: fit-content;
  user-select: none;
  padding: 1em 0;
  background-color: #fff3;
  backdrop-filter: blur(5px);
  overflow: hidden;
  &:first-of-type {
    padding-bottom: 0;
  }

  .menu-title {
    font: $font-style-menu-title;
    padding-left: 0.8rem;
  }

  .menu-option {
    width: 100%;
    padding: 0.3rem 1rem;

    .displayed-option {
      display: flex;
      align-items: center;
      cursor: pointer;

      .option-text {
        font: $font-style-medium;
        font-size: 14px;
        margin-left: 0.4rem;
      }

      .option-icon {
        color: $color-active-light;
        height: 1rem;
        filter: grayscale(1) contrast(4);
        &.has-color {
          box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 0.0625rem inset, rgba(0, 0, 0, 0.25) 0 0 0.25rem inset;
          background-position: 0 0, 0.3125rem 0.3125rem;
          background-size: 1rem 1rem;
        }

        .option-icon-container {
          min-width: 1rem;
          height: 1rem;
        }
      }
    }

    &.selected,
    &:hover {
      background-color: $color-light-green;
      color: white;
      .option-icon {
        filter: brightness(3);
      }
    }
  }
}
