.save-button,
.cancel-button {
  border: none;
  font: inherit;
  border-radius: 0.4rem;
  cursor: pointer;
  width: 5rem;
}

.save-button {
  background-color: $color-light-green;
  color: $background-color-white;
}

.cancel-button {
  color: $color-gray;
}

.MuiInputBase-root.MuiInput-root:after {
  border-bottom: 0.125rem solid $color-light-green;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: $color-light-green;
}

.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 0.06rem solid $color-active-light;
}

.MuiPaper-root.MuiDialog-paper {
  width: 38rem;
}

.MuiTypography-root.MuiDialogTitle-root {
  color: $dark-gray;
  padding: 0.5rem;
  border-bottom: 0.01rem solid $color-grey-disabled;
  margin-bottom: 1rem;
}
