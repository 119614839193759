@import "../../../assets/_variables";

.list-item-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem;
  list-style: none;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-radius: 1rem;
  border: 1px solid $color-very-light-gray;

  .left-list-item-container {
    display: flex;
    height: 100%;
    width: 100%;

    .list-image {
      width: 5rem;
      border-radius: 0.5rem;
      border: 1px solid $color-very-light-gray;
    }

    .list-item-info-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 1rem 0 1rem;

      .info-row-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .key-info {
          font-weight: bold;
        }
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .right-list-item-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .list-item-button {
      width: 1.25rem;
      height: 1.25rem;
      padding: 0.25rem;
      color: white !important;
      border-radius: 0.5rem;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 0.4rem 0.2rem rgba(150, 150, 150, 0.25);
      box-shadow: 0px 0px 0.4rem 0.2rem rgba(150, 150, 150, 0.25);
    }

    .list-item-delete-button {
      background-color: $color-light-red;
    }

    .list-item-edit-button {
      background-color: $color-orange;
    }

    .list-item-cancel-button {
      background-color: $color-red;
    }
  }
}
