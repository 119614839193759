.features-container {
  display: flex;
  width: 95%;
  padding-top: 1.5rem;
  padding-left: 3rem;
  flex-wrap: wrap;
}

ul li {
  font-size: 1rem;
}

ul.list {
  list-style: none;
  text-align: left;
  font-size: 20%;
}

.active-users-currentWeek {
  font: $font-style-medium-bold;
  color: $text-color;
  text-align: left;
  text-transform: uppercase;
}

.card {
  padding: 1rem;
  padding-top: 0%;
}

.assignments-days {
  font-size: 70%;
  color: black;
}

.assignments-days-dailyOcc {
  font-size: 0.75rem;
  color: $color-black;
  font-weight: bold;
  margin-top: 0.5rem;
}

.assignmentDays-container-dailyOcc {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  color: $color-active-light;
}

.assignmentDays-container {
  padding: 0;
  padding-right: 1.75rem;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  color: $color-active-light;
}

.assignment-dataDays {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.wrap-days {
  display: inline-flex;
  padding-top: 0.25rem;
  padding-right: 1.25rem;
  padding-left: 0.5rem;
}

.wrap-days-inv {
  display: inline-flex;
  padding-top: 0.25rem;
  padding-right: 1.25rem;
  padding-left: 0.5rem;
  visibility: hidden;
}

.all-days {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 700px) {
  .features-container {
    display: flex;
    width: 95%;
    padding-top: 1.5rem;
    padding-left: 1rem;
    flex-wrap: wrap;
  }
}
