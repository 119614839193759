.desks-container {
  width: 100%;

  .list-with-right-component-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .list-container {
      width: 70%;
    }

    .desks-container {
      display: flex;
      flex-direction: column;
      padding: 2rem;

      .desk-reservation {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.desk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 2.5rem;
  overflow: auto;
}

.assignment-free-desks,
.reserved-container {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  text-align: left;
}

.assignment {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  text-align: left;
  padding-bottom: 0.5rem;

  .delete-button {
    color: $color-light-red;
    position: static;
    padding-left: 1rem;
    padding-top: inherit;
  }

  .desk {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

h2 {
  font: $font-style-medium;
  text-align: left;
  font-size: 1.5rem;
  color: $color-active;
}

.label {
  font: $font-style-small-bold;
  padding-right: 2.5rem;
  text-align: left;
}

.row {
  display: flex;
}

.assigned-container {
  padding-bottom: 1rem;
}

.assigned-button {
  color: green;
}

.free-hours {
  font: $font-style-small;
  color: $color-light-green;
  text-align: right;
  justify-self: left;

  p {
    margin: 0;
  }
}

.made-by {
  font: $font-style-small;
  color: $subtitle-color;
  text-align: left;
  padding-top: 0.5rem;
  text-align-last: left;
}

.reservee {
  font: $font-style-small;
  text-align: left;
  display: block;
}

.reserved-hours {
  display: inline-flex;
  font: $font-style-small;
  color: $color-light-red;
  vertical-align: top;
}

#asigned-desks {
  color: red;
}

.hide {
  visibility: visible;
  padding: 0.5rem;
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

.mobile-container {
  padding: 0 2.5rem 0 2.5rem;
  height: calc(100vh - 13.3rem);
  overflow: auto;
}

.mobile-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.free-item-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.free-item {
  background-color: $color-light-green;
  border-radius: 5px;
}

.free-item-text {
  color: white;
  align-self: center;
}

.free-item-container .label.free-item-text:hover {
  color: white;
}

.free-item-container .label:hover {
  cursor: pointer;
  color: $color-light-green;
}

@media only screen and (max-width: 700px) {
  .assignment-free-desks {
    visibility: visible;
  }

  .reserved-container {
    grid-template-columns: 1.2fr 1fr;
  }

  .assigned-container {
    visibility: hidden;
  }

  .hide {
    visibility: visible;
    text-align-last: center;

    .reservee {
      text-align-last: left;
    }
  }

  .desk-row {
    justify-content: space-between;
    padding: 0.5rem;
  }

  .assignment {
    visibility: hidden;
  }

  .label {
    padding-right: 0;
    padding-bottom: 0;
    justify-self: self-start;
  }
}

p {
  margin: 0 0 0 0;
}
