.generic-modal {
  cursor: default;
  position: fixed;
  height: 100%;
  width: 25rem;
  box-shadow: -0.313rem 0 0.5rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 11;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.main-modal {
  z-index: 6;
  height: 100%;
  width: 100%;
}

.generic-modal.new-reservation-modal {
  z-index: 13;
}

.content-modal {
  box-sizing: border-box;
  padding: 1rem 2rem;
  width: 25rem;
  background-color: $background-color-white;
  overflow: auto;
}

.header-modal {
  display: flex;
  justify-content: space-between;
}

.title-modal {
  padding-top: 0.5rem;
  font-size: 2rem;
  font-weight: lighter;
}

.button-modal {
  cursor: pointer;
  width: 20rem;
  height: 2.5rem;
  background: $color-light-green 0% 0% no-repeat padding-box;
  border: 0.0625rem solid $color-light-green;
  border-radius: $border-radius-button;
  text-align: center;
  color: $background-color-white;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 100%;
}

.button-modal:hover {
  background: $color-dark-green 0% 0% no-repeat padding-box;
  border: 0.0625rem solid $color-dark-green;
  border-radius: $border-radius-button;
}

#icon-modal {
  font-size: 2rem;
  color: $color-black;
}

#icon-modal:hover {
  color: $color-red;
}

.icon-button-modal:hover {
  background-color: transparent;
}

.form-container-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.275rem;
  min-height: 90%;
  justify-content: space-between;
}

.footer-modal {
  bottom: 0.5rem;
}

@media only screen and (max-width: 700px) {
  .generic-modal {
    left: 0;
    width: 100vw;
  }
}
