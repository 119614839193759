.menu-container {
  .desk-button {
    width: 8.3rem;
    height: 2.125rem;
    background: $color-dark-green 0% 0% no-repeat padding-box;
    border: 0.0625rem solid $color-light-green;
    border-radius: $border-radius-button;
    text-align: center;
    color: $background-color-white;
    text-transform: uppercase;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 0.9rem;
  }

  .desk-button:hover {
    box-shadow: 0 0.275rem 0.275rem $color-light-green;
    border: 0.0625rem solid $color-dark-green;
    border-radius: $border-radius-button;
  }

  .form-container {
    margin-top: 1rem;
    width: 8.3rem;

    .input-container {
      width: 8.0625rem;
      height: 2.125rem;
      padding-left: 0;
      margin-bottom: -1rem;
      text-align: center;
    }
  }
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.875rem;
}

@media only screen and (min-device-width: 20rem) and (max-device-width: 30rem) {
  .form-container {
    align-items: center;
  }
}
