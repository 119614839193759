@import "../../assets/variables.scss";

.popup-container {
  position: absolute;
  z-index: 1;
  top: 0px;
  height: 0px;
  transition: 100ms ease-in-out;

  .popup-wrapper {
    transform: translate(-50%, 0.75rem);
    position: absolute;
    bottom: 0;
  }

  .popup {
    width: max-content;
    height: fit-content;
    text-align: left;
    user-select: none;
    content: "";
    border-radius: 10px;
    min-width: 15rem;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
      z-index: -1;
      border-radius: 10px;
      background-color: #fff9;
      box-shadow: 0px 0px 5px #78787887;
    }

    .popup-name {
      max-width: 20rem;
      overflow: hidden;
      text-transform: uppercase;
      font-size: small;
      color: $color-light-gray;
    }

    .popup-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 0.5rem;
      margin-top: 0.5rem;
      padding: 0 1rem 0 1rem;

      .popup-lower-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        width: 100%;

        .MuiFormControlLabel-root {
          margin-left: 0;
        }

        .popup-checkboxes-container {
          display: flex;
          flex-direction: column;

          .popup-checkbox {
            padding: 0px;
          }
        }
      }
    }

    .reservation-text {
      color: $color-dark-green;
      text-transform: capitalize;
      margin-left: 0.5rem;
    }

    .delete-reservation-text {
      color: $color-light-red;
      text-transform: capitalize;
      margin-left: 0.5rem;
    }

    .reservation-icon {
      color: $color-dark-green;
    }

    .delete-reservation-icon {
      color: $color-light-red;
    }

    .checkbox-label {
      margin: 0px;
    }

    .popup-upper-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      border-width: 1px;
      margin: 0px 10px 0px 10px;
      padding: 10px;
      border: solid 0.5px $color-light-gray;
      border-radius: 10px;

      .popup-image {
        max-width: 100%;
        max-height: 2rem;
        object-fit: contain;
        margin-right: 10px;
      }

      .popup-label-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        .popup-label {
          font-size: medium;
        }

        .popup-label-icon {
          font-size: medium;
          cursor: pointer;
        }

        .popup-label-icon:hover {
          color: $color-dark-green;
        }

        .public-DraftEditor-content {
          max-height: 5rem;
          max-width: 20rem;
        }
      }
    }
  }

  .spacer {
    height: 1rem;
  }
}

.textarea-label {
  border: none;
  outline: none;
  padding: 0px;
  width: 100%;
  resize: none;
}

.popup-button {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  color: white !important;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0.4rem 0.2rem rgba(150, 150, 150, 0.25);
  box-shadow: 0px 0px 0.4rem 0.2rem rgba(150, 150, 150, 0.25);
}

.popup-save-button {
  background-color: $color-dark-green;
}

.popup-delete-button {
  background-color: $color-light-red;
}

.popup-message-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.reservation-button {
  justify-content: flex-start !important;
  padding: 0 !important;
}
.popup-employee-text {
  font-weight: 500;
  margin-bottom: 0.1rem;
  color: $color-dark-green;
}

.popup-reserved-by-text {
  color: $subtitle-color;
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
  font-style: italic;
}
