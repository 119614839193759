.dropdown-menu {
  width: 12rem;
  height: 2.5rem;
  box-shadow: $box-shadow-dark;

  &.building-dropdown {
    background: $background-color-white url("../../assets/images/building.png") 10% 50% no-repeat padding-box;
  }

  &.floor-dropdown {
    background: $background-color-white url("../../assets/images/floor.png") 10% 50% no-repeat padding-box;
  }

  &.Mui-disabled {
    background-color: $color-grey-disabled;
  }
}

.building-dropdown,
.floor-dropdown {
  display: flex;
  justify-content: center;
}

.MuiPaper-root {
  .MuiList-root {
    .MuiMenuItem-root {
      &.Mui-disabled {
        display: none;
      }
    }
  }
}

.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.add-button {
  text-align: inherit;
  color: $color-dark-green;

  svg {
    font-size: 1rem;
    margin-bottom: -0.15rem;
    margin-right: 0.3rem;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  max-height: 13rem;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
  padding-left: 2.6rem;
  text-align: left;
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: $border-color-grey;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0.625rem $color-faded-gray;
  border-radius: 1rem;
}

.buttons {
  display: inline-flex;
  text-align: center;

  .delete-button {
    position: relative;
    border: none;
    background: transparent;
    cursor: pointer;
    color: $color-very-light-red;
    display: flex;
    padding-right: 0;
  }
  .edit-button {
    position: relative;
    border: none;
    background: transparent;
    cursor: pointer;
    color: $color-darker-gray;
    display: flex;
    right: 0.3rem;
  }
}

.padding {
  padding-top: 0.5rem;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 7.5rem;
  background-color: $color-lightest-gray;
  color: $color-darker-gray;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.313rem 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 2.7rem;
  left: -2.5rem;
}
.tooltip .tooltiptext-menu {
  visibility: hidden;
  width: 5rem;
  background-color: $color-lightest-gray;
  color: $color-darker-gray;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.313rem;
  position: absolute;
  z-index: 1;
  left: -1.5rem;
  top: 3rem;
  font-size: 1rem;
}
.tooltip:hover .tooltiptext-menu {
  visibility: visible;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.mydesk {
  padding-left: 2rem;
  position: relative;
  margin-top: -3px;
  color: $color-red-faded;
}
@media only screen and (max-width: 700px) {
  .dropdown-menu {
    &.building-dropdown {
      background: $background-color-white url("../../assets/images/building.png") 5% 50% no-repeat padding-box;
    }

    &.floor-dropdown {
      background: $background-color-white url("../../assets/images/floor.png") 5% 50% no-repeat padding-box;
    }

    &.Mui-disabled {
      background-color: $color-grey-disabled;
    }
  }
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    padding-left: 2.6rem;
    text-align: left;
  }
  .mydesk {
    padding-left: 1.2rem;
  }
  .buttons {
    width: 2.4rem;
    position: relative;
    display: inline-flex;

    .delete-button {
      display: flex;
      position: relative;
    }
    .edit-button {
      display: flex;
      position: relative;
    }
  }
}
