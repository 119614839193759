.members-list {
  padding: 0;
}

.team-member-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  .left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0.75rem;
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
      color: $color-gray;
    }

    p {
      font-size: 1rem;
      margin: 0;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
  }
}

.avatar-wrapper {
  object-fit: cover;
  width: 2.813rem;
  height: 2.813rem;
  border-radius: 50%;
}

.members-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.button-text {
  text-transform: none;
}

#new-member {
  cursor: pointer;
  color: $color-light-green;
  text-align: center;
  font-family: "Open Sans";
  font-size: 100%;
  text-transform: none;
  margin-left: 2.5rem;
  background-color: transparent;
  background: none;

  &:hover {
    color: $color-dark-green;
    background-color: transparent;
  }
}

.input-with-icon-textfield input + .MuiInputBase-root-MuiOutlinedInput-root {
  border-color: $color-light-green;
  border-width: 0.0625rem;
}
.input-with-icon-textfield input:focus + .MuiInputBase-root-MuiOutlinedInput-root {
  border-color: $color-light-green;
  border-width: 0.0625rem;
}
.profile-form-container .MuiFormControl-root.MuiTextField-root {
  width: 20rem;
  box-shadow: $box-shadow-dark;
}

#select-textfield {
  border-color: $color-light-green;
  border-radius: $border-radius-button;
  padding: 0.6rem;
  box-shadow: none;
}
#select-textfield:hover ~ fieldset,
#select-textfield:active ~ fieldset #select-textfield:focus ~ fieldset {
  border-color: $color-light-green;
  border-width: 0.0625rem;
}
