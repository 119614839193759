.main-container {
  width: 100%;
  height: 91vh;
  display: inline-flex;
  background-color: $background-calendar-color;

  .left-container {
    width: 25%;
    display: flex;
    flex-direction: column;

    .next-container {
      height: 40%;
      width: 90%;
      margin: 2rem 1rem 1rem 1rem;

      h3 {
        text-align: left;
        font-size: 1.5rem;
        padding-left: 1rem;
        margin-bottom: 0;
      }

      .next-reservations {
        height: 90%;
        overflow-y: auto;
        padding: 0.5rem 0 0 1rem;

        .assignment-container {
          padding-top: 1rem;
        }
      }
    }

    .availability {
      height: 40%;
      width: 90%;
      margin: 1.4rem 1rem 1rem 1rem;
      text-align: -webkit-center;

      .av-title {
        display: flex;
        place-content: space-between;
        align-items: center;

        h3 {
          text-align: left;
          font-size: 1.5rem;
          padding-left: 1rem;
        }
      }

      .custom-calendar {
        width: 12rem;
        display: grid;
        margin-right: 1rem;
        grid-template-columns: 1fr 3fr 1fr;

        .MuiSvgIcon-root {
          margin-left: 0.8rem;
        }
      }

      .calendar-cont {
        margin-left: 2rem;

        .react-calendar {
          max-width: 13rem;
          left: -1.5rem;
        }
      }

      .item-container {
        height: 70%;
        overflow-y: auto;
        margin-top: 1rem;

        .taken {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 0 0 0.5rem 0;

          .av-content {
            padding-left: 1rem;
            text-align-last: left;

            .av-floor {
              font: $font-style-medium-bold;
              color: $color-active;
            }

            .av-building {
              font: $font-style-small;
              color: $color-light-gray;
            }
          }

          .nr-content {
            padding-left: 3rem;
            text-align-last: left;
            justify-self: left;

            .nr-taken {
              font: $font-style-small-bold;
              color: $color-light-red;
            }

            .nr-free {
              color: $color-light-green;
            }
          }
        }
      }
    }
  }

  .right-container {
    width: 75%;

    .rbc-calendar {
      padding: 0rem 2rem 0 0;
      width: 100%;
      background-color: $background-color-white;

      .rbc-toolbar {
        background-color: $background-calendar-color;
        margin-bottom: 0;
        padding-bottom: 1rem;

        .rbc-toolbar-label {
          font: $font-style-navbar-title;
        }

        .rbc-btn-group:last-child {
          button:last-child {
            display: none;
          }

          button:nth-last-child(-n + 2) {
            display: none;
          }
        }
      }

      .rbc-month-view {
        border: none;
        border: 1px solid #fafafa;

        .rbc-month-header {
          border-left: 1px solid #fafafa;
          background-color: $background-calendar-color;

          .rbc-header {
            font: $font-style-calendar-title;
            text-align: left;
            padding-left: 0.5rem;
            border: 1px solid #fafafa;
          }
        }

        .rbc-month-row {
          border: 1px solid #fafafa;

          .rbc-row-bg > .rbc-day-bg {
            border-left: 1px solid #fafafa;
          }
        }
      }

      .rbc-btn-group > button {
        background-color: $color-light-green;
        color: $background-color-white;
      }

      .rbc-btn-group > button:hover {
        background-color: $color-dark-green;
        color: $background-color-white;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-container {
    display: flex;
    flex-direction: column;
    background-color: white;

    .left-container {
      position: relative;
      width: 100%;
      .next-container {
        margin: 2rem 1rem 2rem 1rem;
        height: 30%;
        .next-reservations {
          height: 50%;
          margin-top: 2rem;
          .assignment-container {
            grid-template-columns: 2fr 1.5fr 1fr;
          }
        }
      }
    }

    .right-container {
      margin-top: 30rem;
      position: absolute;
      width: 100%;
      padding-right: 0;
      .rbc-calendar {
        height: 400px;
        padding: 0 2rem 0 2rem;
        .rbc-toolbar {
          display: flex;
          flex-direction: column;
          background-color: white;
          .rbc-toolbar-label {
            // days interval label
            order: 1;
            position: relative;
            font: $font-style-small-bold;
          }

          .rbc-btn-group:first-child {
            //back btn
            order: 2;
          }

          .rbc-btn-group:last-child {
            //next btn
            order: 3;
          }
          .rbc-btn-group:first-child > button:first-child {
            // today btn
            margin-top: 1.15rem;
            margin-left: -2.8rem;
            position: absolute;
            color: black;
            background-color: white;
            border-color: #8bc34a;
            border-radius: 2px;
          }
          .rbc-btn-group:last-child > button:first-child {
            //month btn
            margin-left: 5.5rem;
            color: black;
            background-color: white;
            border-color: #8bc34a;
            border-radius: 2px;
          }
          .rbc-btn-group:last-child > button:not(:first-child) {
            //hide week agenda day
            display: none;
          }
          .rbc-btn-group button:nth-last-child(-n + 2) {
            //back btn
            font-size: 0;
            position: absolute;
            margin-top: -1.5rem;
            margin-right: 2rem;
            left: 2rem;
          }

          .rbc-btn-group button:nth-last-child(-n + 2)::after {
            font-size: 1rem;
            content: "<";
          }
          .rbc-btn-group > button:not(:first-child):not(:last-child) {
            border-radius: none;
          }

          .rbc-btn-group > button:not(:first-child):not(:last-child) {
            //back btn border
            border-radius: 2px;
          }

          .rbc-btn-group > button:last-child:not(:first-child) {
            //next btn
            border-radius: 2px;
          }
          .rbc-btn-group button:last-child {
            //next btn
            font-size: 0;
            position: relative;
            margin-top: -2.5rem;
            left: 9.5rem;
          }

          .rbc-btn-group button:last-child::after {
            font-size: 1rem;
            content: ">";
          }
        }
        .rbc-month-view {
          .rbc-month-header {
            .rbc-header {
              font: $font-style-small-bold;
            }
          }
          .rbc-month-row {
            .rbc-row-content {
              .rbc-row {
                .rbc-row-segment {
                  .rbc-event {
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    margin: 0.8rem auto auto auto;
                    .rbc-event-content {
                      display: none;
                    }
                  }
                }
              }
              .rbc-row:not(:nth-child(-n + 2)) {
                display: none;
              }
            }
          }
        }
      }
    }

    .left-container {
      .availability {
        .av-title {
          flex-direction: column;
          align-items: flex-start;
          h3 {
            margin: 0 0 0.5rem 0;
          }
        }
        .custom-calendar {
          width: 15rem;
          height: 2rem;
        }
      }
      .copyright-wrapper {
        position: fixed;
        padding-left: 1rem;
      }
    }
  }
}
