.interval-container {
  margin-top: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .MuiFormControl-root.MuiTextField-root {
    justify-content: space-around;
    width: 39%;
    border: 0.063rem solid $color-very-light-gray;
    text-align: center;
    border-radius: 0.3rem;
    box-shadow: $box-shadow-dark;
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
    padding: 0.4rem;
    font: normal normal normal 1rem/1.5rem "OpenSans";

    &::placeholder {
      visibility: hidden;
    }
  }
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd:focus ~ fieldset {
    border-color: $color-light-green;
    border-radius: $border-radius-button;
    box-shadow: 0 0 0.35rem $color-light-green;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
    left: 0.2rem;
    padding: 0;
    bottom: 0.1rem;
  }

  .MuiFormHelperText-root.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained {
    margin-left: 8.6rem;
    color: rgb(175 174 174 / 60%);
    margin-top: -1.3rem;
    white-space: nowrap;
  }

  .label {
    font-weight: 700;
    font-size: 0.7rem;
    margin-top: 1.5rem;
  }
}

.others-container h5 {
  margin: 0;
  padding-top: 0.5rem;
  text-align: left;
  font-weight: 500;
}
