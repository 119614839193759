@import "../../../assets/variables";

$spacing-value-small: 1rem;
$spacing-value-medium: 2rem;
$image-size: 2rem;

.default-toolbar-image {
  object-fit: contain;
  height: $image-size;
  width: $image-size;
}

.default-toolbar-image-overlay {
  opacity: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: $image-size;
  width: $image-size;
  transition: 100ms ease-in-out;
  text-align: center;
  margin: auto;
  line-height: $image-size;
}

.default-toolbar-image-overlay:hover {
  opacity: 1;
}

.default-toolbar-image-overlay:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f2ed";
  color: white;
  width: 100%;
  height: 100%;
}
